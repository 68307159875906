<template>
    <div>
        <el-form :model="ruleForm" :rules="rules" ref="formCheck" label-width="130px" class="form">
            <el-form-item v-if="popTitle=='移动至其他分类'" prop="set_no" label="移动至其他分类">
                <el-select  v-model="ruleForm.set_no" style="width:200px"  placeholder="请选择移动后的分类">
                    <el-option v-for="item in series" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item v-else prop="name" label="分类名称">
                <el-input placeholder="请输入分类名称" style="max-width:240px;" v-model="ruleForm.name"></el-input>
            </el-form-item>
            <div class="flex flex-justify-around" style="padding-top:30px;">
                <div><el-button @click="hide()" style="width: 80px;" type="primary" plain>取消</el-button></div>
                <div><el-button @click="submitForm()" style="width: 80px;" type="primary">提交</el-button></div>
            </div>
        </el-form>
    </div>
</template>
<script>
    import { IMG_URL_PRE } from '@/config';
    import { initSetId, addOrUpdateSet, addOrUpdateProduct } from '@/service/design';
    export default {
        props: ['form','popTitle','set','productForm'],
        data() {
            return {
                rules: {
                    name: [
                        { required: true, message: '请输入商品分类名称', trigger: 'blur' }
                    ],
                    set_no: [
                        { required: true, message: '请选择移动后的分类', trigger: 'blur' }
                    ],
                },
                ruleForm:{
                    id: "", 
                    parent_set_no: "", 
                    name: "",
                    product_rows:[],
                    order_no: 0,
                    set_no: "",
                },
                series: [],
            }
        },
        watch:{
            form:{
                handler() {
                    this.ruleForm = this.form;
                },
                deep: true
            },
            set:{
                handler() {
                    this.series = this.set;
                },
                deep: true
            }
        },
        created() {
            this.ruleForm = this.form;
            this.series = this.set;
            console.log(this.ruleForm,this.series);
        },
        mounted() {},
        methods: {
            submitForm() {
                this.$refs['formCheck'].validate((valid) => {
                    if (valid) {
                        this.submit();
                    }
                });
            },
            submit(){
                console.log("ruleForm",this.ruleForm,this.productForm);
                if (this.popTitle=='新增分类') {
                    initSetId().then(res => {
                        this.ruleForm.id = res.id;
                        let obj = this.ruleForm;
                        addOrUpdateSet(obj).then(res => {
                            console.log("添加子套系",res);
                            this.series.push(this.ruleForm);
                            this.$emit('result',this.series);
                            this.$message.success("添加成功！");
                            this.hide();
                        }).catch(err => {
                            console.log(err);
                            this.$message.error(err.message);
                        });
                    }).catch(err => {
                        console.log(err);
                        this.$message.error(err.message);
                    });
                } else if (this.popTitle=='修改分类') {
                    let setIdx = this.series.findIndex(e => e.id == this.ruleForm.id);
                    console.log(setIdx);
                    addOrUpdateSet(this.ruleForm).then(res => {
                        console.log("修改套系",res);
                        this.$set(this.series, setIdx, this.ruleForm) // 触发视图更新
                        this.$emit('result',this.series);
                        this.$message.success("修改成功！");
                        this.hide();
                    }).catch(err => {
                        console.log(err);
                        this.$message.error(err.message);
                    });
                } else if (this.popTitle=='移动至其他分类') {
                    let setIdx = this.series.findIndex(e => e.id == this.productForm.setNo);
                    if (this.ruleForm.set_no) {
                        let setAfterIdx = this.series.findIndex(e => e.id == this.ruleForm.set_no);
                        // if (this.series[setIdx].product_rows && this.series[setIdx].product_rows.length>0) {
                            
                        // }
                        let productIdx = this.series[setIdx].product_rows.findIndex(e => e.id == this.productForm.id);
                        let obj = {
                            id: this.productForm.id,
                            set_no: this.ruleForm.set_no
                        }
                        addOrUpdateProduct(obj).then(res => {
                            console.log("修改套系",res);
                            this.series[setIdx].product_rows.splice(productIdx,1);
                            this.productForm.setNo = this.series[setAfterIdx].id;
                            this.series[setAfterIdx].product_rows.push(this.productForm);
                            // this.$set(this.series, setIdx, this.ruleForm) // 触发视图更新
                            this.$emit('result',this.series);
                            this.$message.success("移动成功！");
                            this.hide();
                        }).catch(err => {
                            console.log(err);
                            this.$message.error(err.message);
                        });
                    }
                }
            },
            hide() {
                this.$emit('hide');
            },
        }
    }
</script>
<style scoped>
    .form {
        width: 450px;
        margin: 0 auto;
    }
    
</style>
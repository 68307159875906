<template>
  <div class="zt-page-content" ref="load">
    <div v-if="stepShow && step==1" class="step1">
      <EditStep1
        v-if="setting"
        ref="form_check_save"
        :form="product"
        :setting="setting"
        @saveProduct="saveProduct"
      ></EditStep1>
      <SeriesEditStep1
        v-if="!setting"
        ref="form_check1"
        v-model="ruleForm"
        :detail="detail"
        :set_row="sub_set_row"
        @showProduct="showProduct"
        @step="addStep"
      ></SeriesEditStep1>
    </div>
    <div v-if="stepShow && step>=2" class="step2">
      <EditStep2 ref="form_check2" :form="ruleForm" :type="'set'" @step="addStep"></EditStep2>
    </div>

    <div class="zt-block" style="padding: 20px 0">
      <div class="flex flex-justify-around" style="max-width:400px;margin:0 auto;">
        <div v-if="setting">
          <el-button size="small" type="primary" @click="hideProduct()">取消</el-button>
          <el-button size="small" @click="submitProduct()" type="primary">保存</el-button>
        </div>
        <div v-else>
          <el-button size="small" type="primary" @click="back()">取消</el-button>
          <el-button v-if="step<2" size="small" @click="submitForm()" type="primary">下一步</el-button>
          <el-button v-if="step>=2" size="small" @click="lastStep()" type="primary">上一步</el-button>
          <el-button v-if="step>=2 && ruleForm.price_type==4" size="small" @click="submitForm()" type="primary">保存</el-button>
          <el-button v-if="step>=2 && ruleForm.price_type!=4" size="small" @click="submitForm()" type="primary">发布</el-button>
        </div>
      </div>
    </div>

    <el-dialog width="550px" :title="popTitle" :visible.sync="popVisible" :destroy-on-close="true">
      <div v-if="popTitle=='温馨提示'">
        <div style="width:80%;margin:0 auto;text-align:center;padding-bottom:20px;">
          <p style="font-size:16px;color:#292929;">作品发布确认</p>
          <p style="font-size:14px;color:#808080;">作品发布后，工厂即可在设计超市中浏览和购买，请仔细核对作品详情和价格</p>
        </div>
        <div style="text-align:center;border-top:1px solid #ccc;padding-top:25px;">
          <el-button
            @click="submitHide()"
            style="margin-right:20px;"
            size="small"
            type="primary"
            plain
          >再修改一下</el-button>
          <el-button @click="submit" size="small" type="primary">确认并发布</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { initSetId, seriesDetail, addOrUpdateSet } from "@/service/design";
import SeriesEditStep1 from "./SeriesEditStep1";
import EditStep1 from "../single/EditStep1";
import EditStep2 from "../single/EditStep2";
import { removeItem } from "localforage";
import designWorkStorage from "@/utils/designWorkStorage";
export default {
  components: {
    SeriesEditStep1,
    EditStep2,
    EditStep1
  },
  computed: {},
  data() {
    return {
      popTitle: null,
      popVisible: false,
      step: 1,
      stepShow: false, // 刷新子组件
      ruleForm: {
        id: "",
        parent_set_no: "",
        name: "",
        photos: [],
        style_no: "",
        order_no: 0,
        price: "",
        price_type: 4,
        contract_file: "",
        custom_cover_page: 0
      },
      product: {},
      setting: null, // 添加商品配置
      sub_set_row: {}, // 分类下的单个商品
      product_no: "",
      saveId: null,
      detail: null
    };
  },
  watch: {
    step: {
      handler() {
        if (this.product_no && this.step == 1) {
          this.refreshItem();
          // this.stepShow = false;
          // this.$nextTick(() => {
          //     this.stepShow = true;
          // })
        }
      },
      deep: true
    },
    setting:{ // 添加完套系又添加单品出来
        handler() { 
            if (this.saveId) {
              this.ruleForm.id = this.saveId;
              this.refreshItem();
            }
        },
        deep: true
    }
  },
  async created() {
    this.product_no = this.$route.query.set_id;
    this.saveId = sessionStorage.getItem("saveId"); // 用于初次创建套系
    if (!this.saveId && !this.product_no) {
      this.getId();
    }
    if (this.saveId) {
      this.ruleForm.id = this.saveId;
      // this.$router.push("/desgin/stored/series/edit?set_id="+this.saveId);
      // this.refreshItem();
    }
    if (this.product_no && this.step == 1) {
      this.ruleForm.id = this.product_no;
      this.refreshItem();
    }
  },
  destroyed() {
    if (this.saveId) sessionStorage.removeItem("saveId");
  },
  mounted() {},
  methods: {
    back() {
      this.$router.go(-1);
    },
    getId() {
      initSetId()
        .then(res => {
          this.stepShow = true;
          this.ruleForm.id = res.id;
          this.saveId = res.id;
          sessionStorage.setItem("saveId", res.id);
          // addOrUpdateSet(this.ruleForm).then(rst => {
          //     console.log("首次提交",rst);
          //     // this.$router.push("/desgin/stored/series/edit?set_id="+res.id);
          // }).catch(err => {
          //     // this.$router.push("/desgin/stored/series/edit?set_id="+res.id);
          //     console.log(err);
          //     this.$message.error(err.message);
          // });
        })
        .catch(err => {
          console.log(err);
          this.$message.error(err.message);
        });
    },
    refreshItem() {
      this.stepShow = false;
      seriesDetail(this.ruleForm.id)
        .then(rst => {
          // console.log("rst", rst);
          this.stepShow = true;
          this.detail = rst;
          if (rst) {
            this.ruleForm = {
              id: rst.id,
              parent_set_no: rst.parent_set_no,
              name: rst.name,
              photos: rst.photos,
              style_no: rst.style_no,
              order_no: rst.order_no,
              price_type: rst.price_type,
              price: rst.price,
              contract_file: rst.contract_file,
              custom_cover_page: rst.custom_cover_page,
              order_id: rst.order_id, // 订单id，用于需求定制订单
              order_intro: rst.order_intro // 订单备注，用于需求定制订单
            };
            if (rst.price_type != 0) {
              this.ruleForm.price_type=4
            }
          }
          // console.log("ruleForm", this.ruleForm);
        })
        .catch(err => {
          console.log(err);
          this.$message.error(err.message);
        });
    },
    showProduct(setting) {
      // 添加分类下的商品
      console.log("添加或编辑分类下的商品", setting);
      this.setting = setting;
    },
    saveProduct(data) {
      // 保存商品到该分类下
      console.log("保存商品到该分类下", data);
      this.setting = null;
      this.sub_set_row = data;
      // this.stepShow = false;
      // this.$nextTick((e)=>{
      //     this.stepShow = true;
      // })
    },
    submitProduct() {
      // 点击保存商品按钮
      this.$refs.form_check_save.submitForm1();
    },
    hideProduct() {
      this.setting = null;
    },
    lastStep() {
      // 上一步
      this.step--;
    },
    addStep(data, stepNum) {
      // this.ruleForm = data;
      Object.assign(this.ruleForm, data);
      console.log("ruleForm", this.ruleForm);
      if (this.step == 1) {
        // this.submit();
      } else if (this.step >= 2) {
        this.submitShow();
      }
      this.step = stepNum;
    },
    submitForm() {
      // 下一步
      if (this.step == 1) {
        this.$refs.form_check1.submitForm1();
      } else if (this.step >= 2) {
        this.$refs.form_check2.submitForm2();
      }
    },
    submitHide() {
      this.popVisible = false;
    },
    submitShow() {
      if (this.ruleForm.price_type==4) {
        this.$confirm("确定要保存？", "温馨提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            closeOnClickModal: false,
            type: "warning",
        }).then(() => {
            this.submit();
        });
      } else {
        this.popTitle = "温馨提示";
        this.popVisible = true;
      }
    },
    submit() {
      const _loading = this.$loading({
        lock: true,
        text: "拼命加载中......"
      });
      addOrUpdateSet(this.ruleForm)
        .then(rst => {
          // this.step++;
          console.log("this.step", this.step);
          if (this.step > 2) {
            this.$message.success("上传成功");
            if (this.$route.query.orderId) {
              // 需求定制订单跳转过来创建单品，type=3单品，type=4套系
              let obj = designWorkStorage.getCommitDesignWork();
              if (!obj.series) {
                obj.series = [{ id: rst.id, name: rst.name }];
              } else {
                obj.series.push({ id: rst.id, name: rst.name });
              }
              designWorkStorage.setCommitDesignWork(obj);
              this.$router.push(
                "/want/demandCustomize/customizeDetail/" +
                  this.$route.query.orderId +
                  "?type=4"
              );
            } else {
              this.$router.push("/desgin/stored?type=1");
            }
            this.submitHide();
          } else {
            designWorkStorage.removeCommitDesignWork();
          }
          _loading.close();
        })
        .catch(err => {
          designWorkStorage.removeCommitDesignWork();
          _loading.close();
          this.$message.error(err.message);
        });
    }
  }
};
</script>
<style scoped lang="less">
</style>